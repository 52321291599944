<template>
  <el-container>
    <Header pageName="装修审核"></Header>
    <el-main>
      <el-tabs v-model="activeLabel" type="card" @tab-click="handleClick">
        <el-tab-pane label="装修审核" name="1">
          <!-- 装修审核 -->
          <el-form class="el-form-search" label-width="100px">
            <el-form-item label="模板名称：">
              <el-input size="small" v-model="searchItem.name" placeholder="请输入模板名称"></el-input>
            </el-form-item>
            <el-form-item label="提审时间：">
              <el-date-picker v-model="searchItem.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
            </el-form-item>
            <el-form-item label="提交店铺：">
              <el-input size="small" v-model="searchItem.au_name" placeholder="请输入所属店铺"></el-input>
            </el-form-item>
            <el-form-item label="模版分类：">
              <el-select size="small" v-model="searchItem.industry_id" placeholder="请选择">
                <el-option v-for="item in industry_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="审核状态：">
              <el-select size="small" v-model="searchItem.audit_status" placeholder="请选择">
                <el-option v-for="item in audit_status_list" :key="item.status" :label="item.name" :value="item.status"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="30px">
              <el-button type="primary" size="small" @click="get_data_list(1)">搜索</el-button>
              <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
              <No />
            </template>
            <!-- 多选框 批量选择 -->
            <!-- <el-table-column align="center" width="80">
          <template #header>
            <el-checkbox @change="allChange" v-model="all_checked">全选</el-checkbox>
          </template>
          <template v-slot="{ row }">
            <el-checkbox @change="itemCheck(row)" v-model="row.checked"></el-checkbox>
          </template>
        </el-table-column> -->
            <el-table-column prop="name" label="模板名称" align="center"></el-table-column>
            <el-table-column prop="ti_anme" label="模板分类" align="center"></el-table-column>
            <el-table-column prop="au_name" label="店铺名称" align="center"></el-table-column>
            <el-table-column label="模板封面" align="center" width="80">
              <template v-slot="{ row }">
                <el-image style="width: 50px; height: 70px" :src="row.images" :preview-src-list="[row.images]"></el-image>
              </template>
            </el-table-column>
            <el-table-column label="模板价格" align="center">
              <template v-slot="{ row }">
                <div :style="{ color: row.is_free ? '' : 'green' }">{{ row.is_free ? `￥${row.money}` : '免费' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="审核状态" align="center">
              <template v-slot="{ row }">
                <div :style="{ color: !row.audit_status ? 'orange' : row.audit_status == 1 ? '#409EFF' : 'red' }">{{ !row.audit_status ? '待审核' : row.audit_status == 1 ? '通过' : '驳回' }}</div>
              </template>
            </el-table-column>
            <el-table-column label="提审时间" align="center">
              <template v-slot="{ row }">
                {{ getDateformat(row.update_time) }}
              </template>
            </el-table-column>
            <el-table-column prop="u_account" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button type="text" size="samll" @click="lookTemDetailPage(row)">模板详情</el-button>
                <el-button type="text" size="samll" v-if="!row.audit_status" @click="openExamineDrawer(row, 1)">通过</el-button>
                <el-button type="text" size="samll" v-if="!row.audit_status" @click="openExamineDrawer(row, 2)">驳回</el-button>
                <el-button type="text" size="samll" @click="lookTemDetail(row)">审核详情</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData">
            <!-- 批量处理 -->
            <!-- <template #batch>
          <div class="batch">
            <el-checkbox @change="allChange" v-model="all_checked">全选</el-checkbox>
            <el-button size="small" @click="openExamineDrawer">批量审核</el-button>
          </div>
        </template> -->
          </Paging>
        </el-tab-pane>
        <el-tab-pane label="模板分类" name="2">
          <el-button @click="() => (addOrUpdateIndustryFlag = true)" style="margin-bottom: 10px" type="primary" size="small">添加分类</el-button>
          <!-- 模板分类 -->
          <el-form class="el-form-search" label-width="100px">
            <el-form-item label="装修分类：">
              <el-input size="small" v-model="searchSecond.name" placeholder="请输入分类名称"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="30px">
              <el-button type="primary" size="small" @click="getIndustryListTable(1)">搜索</el-button>
              <el-button type="text" size="small" @click="cancelSearchSecond">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="tableList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="name" label="分类名称" align="center"></el-table-column>
            <el-table-column label="组内权重" align="center">
              <template v-slot="{ row }">
                <el-input @blur="changeSort(row)" type="number" style="width: 200px" v-model="row.sort"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template v-slot="{ row }">
                <el-button size="small" type="text" @click="toUpdate(row)">编辑</el-button>
                <el-button size="small" type="text" @click="delIndustry(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Paging :total="tableTotal" :page="tablePage" :pageNum="tableRows" @updatePageNum="updateTableData"></Paging>
        </el-tab-pane>
      </el-tabs>

      <!-- 审核详情弹框 -->
      <el-dialog title="审核详情" :visible.sync="auditRecordFlag" width="1200px">
        <el-table :data="auditRecordList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="审核时间" align="center">
            <template v-slot="{ row }">
              {{ getDateformat(row.create_time) }}
            </template>
          </el-table-column>
          <el-table-column prop="u_name" label="审核人员" align="center"></el-table-column>
          <el-table-column label="审核结果" align="center">
            <template v-slot="{ row }">
              {{ row.status == 1 ? '通过' : '驳回' }}
            </template>
          </el-table-column>
          <el-table-column prop="content" label="反馈详情" align="center"></el-table-column>
        </el-table>
        <Paging :total="lookTemTotal" :page="lookTemPage" :pageNum="lookTemRows" @updatePageNum="lookTemUpdateData"></Paging>
      </el-dialog>
      <!-- 审核右侧抽屉 -->
      <el-drawer title="审批/批量审核" :visible.sync="examineDrawer" direction="rtl">
        <div class="drawerBox">
          <div class="title">请选择审核结果</div>
          <el-radio-group v-model="examineRes">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
          <div class="title">请添加驳回原因</div>
          <el-input type="textarea" rows="6" v-model="rejectRemark"></el-input>
          <div class="btnBox">
            <el-button size="small" @click="cancleExamine">取消</el-button>
            <el-button size="small" type="primary" @click="examineFunction">确定</el-button>
          </div>
        </div>
      </el-drawer>
      <el-drawer title="添加/编辑" :visible.sync="addOrUpdateIndustryFlag" direction="rtl">
        <div class="drawerBox">
          <el-form label-width="100px" class="drawer-form">
            <el-form-item label="分类名称：">
              <el-input size="small" v-model="addOrUpdateObj.name"></el-input>
            </el-form-item>
            <el-form-item label="组内权重：">
              <el-input type="number" size="small" v-model="addOrUpdateObj.sort"></el-input>
            </el-form-item>
          </el-form>
          <div class="btnBox">
            <el-button size="small" @click="() => (addOrUpdateIndustryFlag = false)">取消</el-button>
            <el-button size="small" type="primary" @click="addOrUpdateIndustryFunction(null)">确定</el-button>
          </div>
        </div>
      </el-drawer>
      <!-- 查看模版详情弹框 -->
      <el-dialog title="模板详情" :visible.sync="temDetail" width="500px">
        <div v-if="chooseThat" class="temDetail">
          <div class="top">
            <div class="cupo" @click="copyId($event)">复制链接</div>
            <el-popover placement="bottom" width="200" trigger="click">
              <template>
                <img style="width: 180px" class="qrcode" :src="chooseThat.qrcode" alt="" />
              </template>
              <div class="cupo" slot="reference">二维码预览</div>
            </el-popover>
          </div>
          <div class="iframeBox">
            <iframe :src="chooseThat.url" frameborder="0"></iframe>
          </div>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate.js';
import _ from 'lodash';
import clipboard from '@/util/clipBoard.js';
export default {
  components: {
    Header,
    Paging,
  },
  data() {
    return {
      getDateformat,
      page: 1,
      rows: 10,
      total: 0,
      dataList: [],
      chooseDataList: [],
      all_checked: false,
      searchItem: {
        name: '',
        time: [],
        au_name: '',
        industry_id: 0,
        audit_status: 0,
      },
      industry_list: [{ id: 0, name: '全部' }],
      audit_status_list: [
        { status: 0, name: '全部' },
        { status: 1, name: '待审核' },
        { status: 2, name: '通过' },
        { status: 2, name: '驳回' },
      ],
      // 审核记录弹框
      auditRecordFlag: false,
      // 审核记录列表
      auditRecordList: [],
      lookTemPage: 1,
      lookTemRows: 10,
      lookTemTotal: 0,
      chooseThat: null,
      examineDrawer: false,
      examineRes: 0,
      rejectRemark: '',
      temDetail: false,
      // tabs选中项
      activeLabel: '1',
      searchSecond: {
        name: '',
      },
      tableList: [],
      tablePage: 1,
      tableRows: 10,
      tableTotal: 0,
      addOrUpdateIndustryFlag: false,
      addOrUpdateObj: {
        name: '',
        sort: 0,
      },
    };
  },
  created() {
    this.get_data_list();
    this.get_industry_list();
  },
  methods: {
    // 全选/反选
    allChange() {
      if (this.all_checked) {
        this.dataList.map(item => (item.checked = true));
        this.chooseDataList = _.cloneDeep(this.dataList);
      } else {
        this.dataList.map(item => (item.checked = false));
        this.chooseDataList = [];
      }
    },
    // 单项选择
    itemCheck(row) {
      if (row.checked) {
        this.chooseDataList.push(row);
      } else {
        let index = this.chooseDataList.findIndex(item => item.id === row.id);
        this.chooseDataList.splice(index, 1);
      }
      if (this.chooseDataList.length === this.dataList.length) {
        this.all_checked = true;
      } else {
        this.all_checked = false;
      }
    },
    cancelSearch() {
      this.searchItem = {
        name: '',
        time: [],
        au_name: '',
        industry_id: 0,
        audit_status: 0,
      };
      this.get_data_list(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.get_data_list();
    },
    updateTableData(val, status) {
      if (status == 0) {
        this.tableRows = val;
      } else {
        this.tablePage = val;
      }
      this.getIndustryListTable();
    },
    lookTemUpdateData(val, status) {
      if (status == 0) {
        this.lookTemRows = val;
      } else {
        this.lookTemPage = val;
      }
      this.getAuditLogList(this.chooseThat);
    },
    get_data_list(style) {
      if (style) {
        this.page = 1;
      }
      // 重置所有选中状态
      this.all_checked = false;
      this.chooseDataList = [];
      let that = this;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchItem.name) {
        obj.name = this.searchItem.name;
      }
      if (this.searchItem.au_name) {
        obj.au_name = this.searchItem.au_name;
      }
      if (this.searchItem.industry_id) {
        obj.industry_id = this.searchItem.industry_id;
      }
      if (this.searchItem.audit_status) {
        obj.audit_status = this.searchItem.audit_status;
      }
      if (this.searchItem.time && this.searchItem.time.length) {
        obj.start_time = Math.floor(this.searchItem.time[0] / 1000);
        obj.end_time = Math.floor(this.searchItem.time[1] / 1000);
        if (obj.start_time === obj.end_time) {
          obj.end_time = obj.start_time + 60 * 60 * 24 - 1;
        }
      }
      this.$axios.post(this.$api.admin.websiteTemplate.examineList, obj).then(res => {
        if (res.code == 0) {
          res.result.list.map(item => {
            that.$set(item, 'checked', false);
          });
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    get_industry_list() {
      this.$axios
        .post(this.$api.admin.websiteTemplate.industryList, {
          page: 1,
          rows: 10000,
        })
        .then(res => {
          if (res.code == 0) {
            this.industry_list = this.industry_list.concat(res.result.list);
          }
        });
    },
    // 打开模版审核弹框
    lookTemDetail(val) {
      this.chooseThat = val;
      this.getAuditLogList(val);
      this.auditRecordFlag = true;
    },
    // 获取审核记录
    getAuditLogList(val) {
      this.$axios
        .post(this.$api.admin.websiteTemplate.auditLogList, {
          page: this.lookTemPage,
          rows: this.lookTemRows,
          id: val.id,
        })
        .then(res => {
          if (res.code === 0) {
            this.auditRecordList = res.result.list;
            this.lookTemTotal = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 打开右侧抽屉
    openExamineDrawer(val, status) {
      this.chooseThat = val;
      if (status) {
        this.examineRes = status;
      }
      this.examineDrawer = true;
      // 多选需要处理的项
      // if (this.chooseDataList.length > 0) {
      //   this.examineDrawer = true;
      // } else {
      //   this.$message.warning('请选择要审批的项');
      // }
    },
    // 关闭右侧抽屉
    cancleExamine() {
      this.examineDrawer = false;
      this.examineRes = 0;
      this.rejectRemark = '';
      this.chooseThat = null;
    },
    // 审批函数
    examineFunction() {
      if (!this.examineRes) {
        this.$message.warning('请选择审批结果');
        return;
      }
      if (this.examineRes === 2 && this.rejectRemark == '') {
        this.$message.warning('请填写驳回原因');
        return;
      }
      let obj = {
        id: this.chooseThat.id,
        status: this.examineRes,
      };
      if (this.examineRes === 2) {
        obj.content = this.rejectRemark;
      }
      this.$axios.post(this.$api.admin.websiteTemplate.audit, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('审批成功');
          this.cancleExamine();
          this.get_data_list();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 查看模版详情
    lookTemDetailPage(val) {
      this.chooseThat = val;
      this.temDetail = true;
    },
    copyId(e) {
      clipboard(e, this.chooseThat.url);
    },
    // 点击切换tabs
    handleClick() {
      if (this.activeLabel === '1') {
        this.get_industry_list();
        this.cancelSearch();
      } else if (this.activeLabel === '2') {
        this.cancelSearchSecond();
      }
    },
    cancelSearchSecond() {
      this.searchSecond = { name: '' };
      this.getIndustryListTable(1);
    },
    getIndustryListTable(style) {
      if (style) {
        this.tablePage = 1;
      }
      let obj = {
        page: this.tablePage,
        rows: this.tableRows,
      };
      if (this.searchItem.keyword) {
        obj.name = this.searchSecond.name;
      }
      this.$axios.post(this.$api.admin.websiteTemplate.industryList, obj).then(res => {
        if (res.code == 0) {
          this.tableList = res.result.list;
          this.tableTotal = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeSort(val) {
      this.$axios
        .post(this.$api.admin.websiteTemplate.industryEdit, {
          id: val.id,
          name: val.name,
          sort: val.sort,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('更改权重成功');
          } else {
            this.$message.error(res.msg);
            this.getIndustryListTable();
          }
        });
    },
    delIndustry(val) {
      this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.admin.websiteTemplate.industryDel, {
            id: val.id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getIndustryListTable();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    addOrUpdateIndustryFunction() {
      if (!this.addOrUpdateObj.name) {
        this.$message.warning('请填写分类名称');
        return;
      }
      this.addOrUpdateObj.sort = !this.addOrUpdateObj.sort ? 0 : this.addOrUpdateObj.sort;
      this.$axios.post(this.addOrUpdateObj.id ? this.$api.admin.websiteTemplate.industryEdit : this.$api.admin.websiteTemplate.industryAdd, this.addOrUpdateObj).then(res => {
        if (res.code === 0) {
          this.$message.success(this.addOrUpdateObj.id ? '修改成功' : '添加成功');
          this.addOrUpdateIndustryFlag = false;
        } else {
          this.$message.error(res.msg);
        }
        this.getIndustryListTable(1);
      });
    },
    toUpdate(val) {
      this.addOrUpdateIndustryFlag = true;
      this.addOrUpdateObj = {
        id: val.id,
        name: val.name,
        sort: val.sort,
      };
    },
  },
  watch: {
    addOrUpdateIndustryFlag(val) {
      if (!val) {
        this.addOrUpdateObj = {
          name: '',
          sort: 0,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .batch {
      width: 180px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .drawerBox {
      padding: 0 50px;
      .title {
        margin: 20px 0;
        font-size: 14px;
      }
      .btnBox {
        margin-top: 100px;
        display: flex;
        justify-content: flex-end;
      }
    }
    .temDetail {
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .cupo {
          cursor: pointer;
        }
      }
    }
    .iframeBox {
      margin: 0 auto;
      margin-top: 18px;
      width: 340px;
      height: 677px;
      background-image: url('../../assets/image/site/phoneBg.png');
      background-size: 100% 100%;
      position: relative;
      iframe {
        position: absolute;
        top: 38px;
        left: 8px;
        width: 322px;
        height: 630px;
        border-radius: 0 0 30px 30px;
        overflow: hidden;
      }
    }
  }
}
</style>
